<template>
  <div class="lottery-management">
    <h2>抽奖活动管理</h2>
    
    <!-- 活动列表 -->
    <ul class="activity-list">
      <li v-for="activity in activities" :key="activity.id" class="activity-item">
        <div class="activity-header">
          <span class="activity-name">{{ activity.name }}</span>
          <div>
            <el-tooltip v-if="!activity.hasPrizes" content="请添加奖品" placement="top">
              <i class="el-icon-warning-outline" style="color: #E6A23C; margin-right: 10px;"></i>
            </el-tooltip>
            <el-switch v-model="activity.isOpen" @change="updateActivity(activity)"></el-switch>
          </div>
        </div>
        <div class="activity-info">
          <span>类型: {{ activity.type === LotteryType.Instant ? '即时抽奖' : '定时抽奖' }}</span>
          <span>兑奖截止时间: {{ formatDate(activity.redeemEndTime) }}</span>
        </div>
        <div class="activity-actions">
          <el-button size="mini" @click="editActivity(activity)">编辑</el-button>
          <el-button size="mini" @click="viewPrizes(activity)">奖品</el-button>
          <el-button size="mini" @click="viewRecords(activity)">记录</el-button>
          <el-button size="mini" type="danger" @click="deleteActivity(activity.id)">删除</el-button>
        </div>
      </li>
    </ul>

    <!-- 添加活动按钮 -->
    <div class="add-activity-container">
      <el-button type="primary" @click="addActivity" class="add-activity-btn">添加活动</el-button>
      <el-tooltip content="记得为新活动添加奖品" placement="top">
        <i class="el-icon-info" style="margin-left: 10px;"></i>
      </el-tooltip>
    </div>

    <!-- 添加/编辑活动表单 -->
    <el-dialog :title="isEditing ? '编辑活动' : '添加活动'" :visible.sync="activityDialogVisible" width="90%" custom-class="h5-dialog">
      <el-form :model="currentActivity" label-position="top">
        <el-form-item label="活动名称">
          <el-input v-model="currentActivity.name"></el-input>
        </el-form-item>
        <el-form-item label="是否开启">
          <el-switch v-model="currentActivity.isOpen" disabled></el-switch>
          <el-tooltip content="添加奖品后才能开启活动" placement="top">
            <i class="el-icon-warning-outline" style="margin-left: 10px; color: #E6A23C;"></i>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="抽奖类型">
          <el-select v-model="currentActivity.type" :disabled="isEditing" style="width: 100%;">
            <el-option :value="LotteryType.Instant" label="即时抽奖"></el-option>
            <el-option :value="LotteryType.Scheduled" label="定时抽奖"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="触发关键词">
          <el-input v-model="currentActivity.triggerKeyword"></el-input>
        </el-form-item>
        <el-form-item label="是否需要@">
          <el-switch v-model="currentActivity.requireAtMention" disabled></el-switch>
          <span style="margin-left: 10px; color: #909399;">艾特必须开启</span>
        </el-form-item>
        <el-form-item label="参与关键词">
          <el-input v-model="currentActivity.participateKeyword"></el-input>
        </el-form-item>
        <el-form-item label="退出关键词">
          <el-input v-model="currentActivity.quitKeyword"></el-input>
        </el-form-item>
        <template v-if="currentActivity.type === LotteryType.Scheduled">
          <el-form-item label="开始时间">
            <el-date-picker 
              v-model="currentActivity.startTime" 
              type="datetime" 
              value-format="yyyy-MM-ddTHH:mm:ss.SSSZ"
              :picker-options="pickerOptions"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间">
            <el-date-picker 
              v-model="currentActivity.endTime" 
              type="datetime" 
              value-format="yyyy-MM-ddTHH:mm:ss.SSSZ"
              :picker-options="pickerOptions"
              style="width: 100%;"
            ></el-date-picker>
          </el-form-item>
        </template>
        <el-form-item label="兑奖截止时间">
          <el-date-picker 
            v-model="currentActivity.redeemEndTime" 
            type="datetime" 
            value-format="yyyy-MM-ddTHH:mm:ss.SSSZ"
            :picker-options="pickerOptions"
            style="width: 100%;"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="activityDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveActivity">确定</el-button>
      </span>
    </el-dialog>

    <!-- 奖品管理对话框 -->
    <el-dialog title="奖品管理" :visible.sync="prizesDialogVisible" width="90%" custom-class="h5-dialog">
      <ul class="prize-list">
        <li v-for="prize in prizes" :key="prize.id" class="prize-item">
          <div class="prize-info">
            <span class="prize-name">{{ prize.name }}</span>
            <span class="prize-quantity">总数: {{ prize.totalQuantity }} / 剩余: {{ prize.remainingQuantity }}</span>
            <span class="prize-probability">概率: {{ (prize.probability * 100).toFixed(2) }}%</span>
          </div>
          <div class="prize-actions">
            <el-button size="mini" @click="editPrize(prize)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deletePrize(prize.id)">删除</el-button>
          </div>
        </li>
      </ul>
      <el-form :model="currentPrize" label-position="top" style="margin-top: 20px;">
        <el-form-item label="奖品名称">
          <el-input v-model="currentPrize.name"></el-input>
        </el-form-item>
        <el-form-item label="总数量">
          <el-input-number v-model="currentPrize.totalQuantity" :min="1" style="width: 100%;"></el-input-number>
        </el-form-item>
        <!-- <el-form-item label="概率">
          <el-input-number v-model="currentPrize.probability" :min="0" :max="100" :step="0.1" style="width: 100%;"></el-input-number>
        </el-form-item> -->
        <el-form-item label="概率 (%)">
  <el-tooltip content="请输入 0.01% 到 100% 之间的概率" placement="top">
    <el-input-number 
      v-model="currentPrize.probability" 
      :min="0.01" 
      :max="100" 
      :step="0.01" 
      :precision="2"
      style="width: 100%;"
    ></el-input-number>
  </el-tooltip>
</el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="savePrize">{{ isEditingPrize ? '保存修改' : '添加奖品' }}</el-button>
        <el-button @click="prizesDialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>

    <!-- 抽奖记录对话框 -->
    <el-dialog title="抽奖记录" :visible.sync="recordsDialogVisible" width="90%" custom-class="h5-dialog">
      <ul class="record-list">
        <li v-for="record in records" :key="record.id" class="record-item">
          <span class="record-user">参与者: {{ record.userNick }} ({{ record.userWxid }})</span>
          <span class="record-prize">获得奖品: {{ record.prizeName }}</span>
          <span class="record-time">抽奖时间: {{ formatDate(record.drawTime) }}</span>
          <span class="record-status">状态: {{ record.isRedeemed ? '已兑奖' : '未兑奖' }}</span>
          <el-button 
            v-if="!record.isRedeemed" 
            size="mini" 
            type="success" 
            @click="redeemPrize(record.id)"
          >
            兑奖
          </el-button>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import {
  getLotteryActivities,
  addLotteryActivity,
  updateLotteryActivity,
  deleteLotteryActivity,
  getLotteryPrizes,
  addLotteryPrize,
  updateLotteryPrize,
  deleteLotteryPrize,
  getLotteryRecords,
  redeemLotteryPrize,
  getUserLotteryRecords
} from '@/api';

const LotteryType = {
  Instant: 0,
  Scheduled: 1
};

export default {
  name: 'LotteryManagement',
  props: ['roomwxid'],
  data() {
    return {
      activities: [],
      activityDialogVisible: false,
      prizesDialogVisible: false,
      recordsDialogVisible: false,
      isEditing: false,
      isEditingPrize: false,
      prizes: [],
      records: [],
      currentActivity: {
        id: 0,
        groupId: this.roomwxid,
        name: '',
        isOpen: false,
        triggerKeyword: '',
        requireAtMention: true,
        participateKeyword: '',
        quitKeyword: '',
        type: LotteryType.Instant,
        startTime: '',
        endTime: '',
        redeemEndTime: '',
      },
      currentPrize: {
        id: 0,
        lotteryActivityId: 0,
        name: '',
        totalQuantity: 1,
        remainingQuantity: 1,
        probability: 0,
      },
      LotteryType,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7; // 禁用今天之前的日期
        },
      },
    };
  },
  methods: {
    async fetchActivities() {
      try {
        const response = await getLotteryActivities(this.roomwxid);
        if (response.data.code === 2000) {
          this.activities = response.data.data.map(activity => ({
            ...activity,
            hasPrizes: activity.prizeCount > 0 // 假设后端返回 prizeCount 字段
          }));
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('获取抽奖活动列表失败:', error);
        Message.error('获取抽奖活动列表失败');
      }
    },
    async updateActivity(activity) {
      
      
      const newIsOpen = activity.isOpen;
      
      try {
        const updatedActivity = { ...activity, isOpen: newIsOpen };
        const response = await updateLotteryActivity(updatedActivity);
        
        if (response.data.code === 2000) {
          // API 调用成功，更新本地状态
          this.$set(activity, 'isOpen', newIsOpen);
          Message.success('活动状态更新成功');
        } else {
          // API 返回错误，保持原状态
          Message.error(response.data.message || '更新活动状态失败');
          this.$set(activity, 'isOpen', !newIsOpen);
        }
      } catch (error) {
        console.error('更新活动失败:', error);
        Message.error('网络错误，更新活动状态失败');
      } finally {
      }
    },
    async deleteActivity(activityId) {
      try {
        const response = await deleteLotteryActivity(activityId);
        if (response.data.code === 2000) {
          Message.success('活动删除成功');
          await this.fetchActivities();
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('删除活动失败:', error);
        Message.error('删除活动失败');
      }
    },
    addActivity() {
      this.isEditing = false;
      this.currentActivity = {
        id: 0,
        groupId: this.roomwxid,
        name: '',
        isOpen: false,
        triggerKeyword: '',
        requireAtMention: true,
        participateKeyword: '',
        quitKeyword: '',
        type: LotteryType.Instant,
        startTime: '',
        endTime: '',
        redeemEndTime: '',
      };
      this.activityDialogVisible = true;
    },
    editActivity(activity) {
      this.isEditing = true;
      this.currentActivity = { 
        ...activity,
        startTime: activity.startTime ? this.formatDateForEdit(activity.startTime) : '',
        endTime: activity.endTime ? this.formatDateForEdit(activity.endTime) : '',
        redeemEndTime: activity.redeemEndTime ? this.formatDateForEdit(activity.redeemEndTime) : '',
      };
      this.activityDialogVisible = true;
    },
    formatDateForEdit(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },
    validateKeywords() {
      const { triggerKeyword, participateKeyword, quitKeyword } = this.currentActivity;
      
      if (!triggerKeyword || !participateKeyword) {
        Message.error('触发关键词和参与关键词为必填项');
        return false;
      }

      const keywords = [triggerKeyword, participateKeyword];
      if (quitKeyword) keywords.push(quitKeyword);

      const uniqueKeywords = new Set(keywords);
      if (uniqueKeywords.size !== keywords.length) {
        Message.error('触发关键词、参与关键词和退出关键词不可重复');
        return false;
      }

      return true;
    },
    async saveActivity() {
      if (!this.validateKeywords()) {
        return;
      }

      if (this.currentActivity.type === LotteryType.Scheduled) {
        if (!this.currentActivity.startTime || !this.currentActivity.endTime) {
          Message.error('请设置开始时间和结束时间');
          return;
        }
        const startTime = new Date(this.currentActivity.startTime);
        const endTime = new Date(this.currentActivity.endTime);
        if (startTime >= endTime) {
          Message.error('结束时间必须晚于开始时间');
          return;
        }

        if (!this.currentActivity.redeemEndTime) {
          Message.error('请设置兑奖截止时间');
          return;
        }

        const redeemEndTime = new Date(this.currentActivity.redeemEndTime);
        if (redeemEndTime <= endTime) {
          Message.error('兑奖截止时间必须晚于活动结束时间');
          return;
        }
      } else {
        if (!this.currentActivity.redeemEndTime) {
          Message.error('请设置兑奖截止时间');
          return;
        }
      }

      try {
        let activityToSave = { ...this.currentActivity };
        
        // 确保 requireAtMention 始终为 true
        activityToSave.requireAtMention = true;
        
        if (this.currentActivity.type === LotteryType.Instant) {
          const now = new Date();
          activityToSave.startTime = now.toISOString();
          activityToSave.endTime = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate()).toISOString();
        } else {
          activityToSave.startTime = this.convertToUTC(this.currentActivity.startTime);
          activityToSave.endTime = this.convertToUTC(this.currentActivity.endTime);
        }
        
        activityToSave.redeemEndTime = this.convertToUTC(this.currentActivity.redeemEndTime);

        const apiCall = this.isEditing ? updateLotteryActivity : addLotteryActivity;
        const response = await apiCall(activityToSave);
        if (response.data.code === 2000) {
          Message.success(this.isEditing ? '活动更新成功' : '活动添加成功');
          this.activityDialogVisible = false;
          await this.fetchActivities();
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('保存活动失败:', error);
        Message.error('保存活动失败');
      }
    },
    convertToUTC(dateTimeString) {
      const date = new Date(dateTimeString);
      return date.toISOString();
    },
    formatDate(dateString) {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    },
    async viewPrizes(activity) {
      try {
        const response = await getLotteryPrizes(activity.id);
        if (response.data.code === 2000) {
          this.prizes = response.data.data;
          this.currentPrize.lotteryActivityId = activity.id;
          this.prizesDialogVisible = true;
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('获取奖品列表失败:', error);
        Message.error('获取奖品列表失败');
      }
    },
    editPrize(prize) {
      this.isEditingPrize = true;
      this.currentPrize = { ...prize, probability: prize.probability * 100 };
    },
    async savePrize() {
      try {
        const prizeToSave = {
          ...this.currentPrize,
          probability: this.currentPrize.probability / 100
        };
        const apiCall = this.isEditingPrize ? updateLotteryPrize : addLotteryPrize;
        const response = await apiCall(prizeToSave);
        if (response.data.code === 2000) {
          Message.success(this.isEditingPrize ? '奖品修改成功' : '奖品添加成功');
          await this.viewPrizes({ id: this.currentPrize.lotteryActivityId });
          this.resetPrizeForm();
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('保存奖品失败:', error);
        Message.error('保存奖品失败');
      }
    },
    async deletePrize(prizeId) {
      try {
        const response = await deleteLotteryPrize(prizeId);
        if (response.data.code === 2000) {
          Message.success('奖品删除成功');
          await this.viewPrizes({ id: this.currentPrize.lotteryActivityId });
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('删除奖品失败:', error);
        Message.error('删除奖品失败');
      }
    },
    resetPrizeForm() {
      this.isEditingPrize = false;
      this.currentPrize = {
        id: 0,
        lotteryActivityId: this.currentPrize.lotteryActivityId,
        name: '',
        totalQuantity: 1,
        remainingQuantity: 1,
        probability: 0,
      };
    },
    async viewRecords(activity) {
      try {
        const response = await getLotteryRecords(activity.id);
        if (response.data.code === 2000) {
          this.records = response.data.data;
          this.recordsDialogVisible = true;
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('获取抽奖记录失败:', error);
        Message.error('获取抽奖记录失败');
      }
    },
    async redeemPrize(recordId) {
      try {
        const response = await redeemLotteryPrize(recordId);
        if (response.data.code === 2000) {
          Message.success('兑奖成功');
          await this.viewRecords(this.currentActivity);
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('兑奖失败:', error);
        Message.error('兑奖失败');
      }
    },
    async viewUserRecords() {
      try {
        const response = await getUserLotteryRecords(this.roomwxid, this.currentUserWxid);
        if (response.data.code === 2000) {
          this.userRecords = response.data.data;
          this.userRecordsDialogVisible = true;
        } else {
          Message.error(response.data.message);
        }
      } catch (error) {
        console.error('获取用户抽奖记录失败:', error);
        Message.error('获取用户抽奖记录失败');
      }
    },
  },
  mounted() {
    this.fetchActivities();
  },
};
</script>

<style scoped>
.lottery-management {
  padding: 15px;
  font-size: 14px;
}

h2 {
  font-size: 20px;
  margin-bottom: 15px;
  text-align: center;
}

.activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.activity-item {
  background-color: #f5f7fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.activity-name {
  font-weight: bold;
  font-size: 16px;
}

.activity-info {
  margin-bottom: 10px;
  color: #606266;
}

.activity-actions {
  display: flex;
  justify-content: space-between;
}

.add-activity-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.add-activity-btn {
  flex-grow: 1;
}

.prize-list, .record-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.prize-item, .record-item {
  background-color: #f5f7fa;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.prize-info, .record-item {
  display: flex;
  flex-direction: column;
}

.prize-name, .record-user {
  font-weight: bold;
}

.prize-quantity, .prize-probability, .record-prize, .record-time, .record-status {
  font-size: 12px;
  color: #606266;
}

.prize-actions {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.h5-dialog {
  width: 90% !important;
  max-width: none !important;
}

.h5-dialog .el-dialog__body {
  padding: 15px;
}

.el-form-item {
  margin-bottom: 15px;
}
</style>