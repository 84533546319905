import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login.vue';
import User from '@/views/user.vue';
import ChatConfig from '@/views/ChatConfig.vue';
import ImageGenConfig from '@/views/ImageGenConfig.vue';
import LotteryManagement from '@/views/LotteryManagement.vue';
import JokeConfig from '@/views/JokeConfig.vue';
import WeatherConfig from '@/views/WeatherConfig.vue';
import WallpaperConfig from '@/views/WallpaperConfig.vue';
import NotFound from '@/views/NotFound.vue';
import HistoryConfig from '@/views/HistoryConfig.vue';
import UserLotteryRecords from '@/views/userLotteryRecords.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  { path: '/user', component: User, meta: { requiresAuth: true } },
  {
    path: '/chat-config',
    component: ChatConfig,
    props: (route) => ({ botwxid: route.query.botwxid, roomwxid: route.query.roomwxid })
  },
  {
    path: '/image-gen-config',
    component: ImageGenConfig,
    props: (route) => ({ botwxid: route.query.botwxid, roomwxid: route.query.roomwxid })
  },
  {
    path: '/lottery-management',
    component: LotteryManagement,
    props: (route) => ({ roomwxid: route.query.roomwxid })
  },
  {
    path: '/joke-config',
    component: JokeConfig,
    props: (route) => ({ roomwxid: route.query.roomwxid })
  },
  {
    path: '/weather-config',
    component: WeatherConfig,
    props: (route) => ({ roomwxid: route.query.roomwxid })
  },  {
    path: '/history-config',
    component: HistoryConfig,
    props: (route) => ({ roomwxid: route.query.roomwxid })
  },
  {
    path: '/wallpaper-config',
    component: WallpaperConfig,
    props: (route) => ({ roomwxid: route.query.roomwxid })
  },
  {
    path: '/user-lottery-records',
    name: 'UserLotteryRecords',
    component: UserLotteryRecords
  },
  // {
  //   path: '/keywordReplyManagement',
  //   name: 'KeywordReplyManagement',
  //   component: KeywordReplyManagement
  // },
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next('/login');
  } else {
    next();
  }
});

export default router;